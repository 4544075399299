import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-traveler-quotes',
  templateUrl: './traveler-quotes.component.html',
  styleUrls: ['./traveler-quotes.component.css']
})
export class TravelerQuotesComponent implements OnInit, OnDestroy {
  quoteInterval: any;
  name: string;
  quote: string;
  currentQuote: number;
  // @Input() quoteSet: string;
  // jArray: JSON;

  public recommendations = [
    // tslint:disable-next-line:max-line-length
    { 'name': 'Andrew Trauma RN', 'quote': 'I really like Rvisions because of the personal care I receive. It feels very \"mom and pop." Whenever I call, I almost always get an answer but if not, usually very soon after. As an independent contractor, the income is the best in the industry. I love the transparency. There are very few if any companies willing to discuss the bill rate with the traveler. I challenge you to find one. While this is a small company, you have access to tons of high paying jobs and the you get the 1-on-1 time that makes the experience perfect.' },
      // tslint:disable-next-line:max-line-length
    { 'name': 'Robert ORT', 'quote': '14 years with this company…14 YEARS…that says it all' },
      // tslint:disable-next-line:max-line-length
    { 'name': 'Sheila RNFA', 'quote': 'Keri is the most amazing recruiter I have worked with and I have worked with many, I would say at least 20. I am an experienced traveler and this company ROCKS!'},
      // tslint:disable-next-line:max-line-length
    { 'name': 'Tyler STICU RN', 'quote': 'I\'m not another number at Rvisions. I have the owners cell phone number. I don\'t even know who the owners are at the bigger companies. Everybody I\'ve talked to at Rvisions has been very kind. It\'s like a family. My recruiter is down to earth and doesn\'t hide anything from me. There is no fine print to read as everything is very black and white. You know exactly what you\'re getting. Extremely prompt in returning phone calls. Recruiter Keri is the mother Theresa of all recruiters. Keri made me and the staff I work with some awesome cupcakes on my first shift and it made me very popular.' },
      // tslint:disable-next-line:max-line-length
    { 'name': 'Ruby ICU Travel RN', 'quote': 'I have worked with R Visions for the past several years. I cannot recommend this company highly enough. I have passed their name on to numerous friends and they feel the same. Really nice, pleasant, generous people to work with. Lyn and Mel have been awesome. Never a single problem and always very helpful.' },
      // tslint:disable-next-line:max-line-length
    { 'name': 'Rebecca ICU/ER', 'quote': 'I have been a bedside nurse with Lyn, she is a person you can trust. Honest, fun, and truly cares about creating the best future for everyone that works with her.' },
      // tslint:disable-next-line:max-line-length
    { 'name': 'Trish OB/L&D RN', 'quote': 'I have worked with R Visions for almost 6 years and am totally satisfied. They have not only found me very acceptable contracts but have helped me get all the particulars together as to where I can stay etc. They also are concerned enough about me when they don\'t get an invoice for my time from me they call to see if I was on vacation or what. I feel that they are there to help me any way they can.' },
      // tslint:disable-next-line:max-line-length
    { 'name': 'Ernie Critical Care RN', 'quote': 'I have been with this company at my leisure for 12 years and cannot recommend them more. They are the first company that I call when I am ready for that next assignment.” Sirene CNA I started working for R Visions in 2008. Pay was the best that I could find, and I was paid weekly and always on time. Mel became a true friend that I trusted and always knew I could count on.' },
      // tslint:disable-next-line:max-line-length
    { 'name': 'Pam Med-Surg RN', 'quote': 'I have been working with R Visions for about 4 years, and honestly...after being a nurse for more than 30 years...this has been my favorite!!! They are always there for me. I never feel like I am out there alone. Good assignments,always get paid on time,people who are nurses on the other end of the phone,should I ever have a concern,that truly understand. I feel that,although we are all miles apart,that we are one family who all care about each other. It is so nice when I am starting a new assignment,and nervous because I won\'t know anyone,so many times there is someone else there from R Visions! Love you guys..thanks for all the hard work you do!!' },
      // tslint:disable-next-line:max-line-length
    { 'name': 'Mel Johnson, COO', 'quote': 'I traveled with 5 other companies before finding R Visions. After doing some assignments with them, I quickly realized that this was company unlike any other I’d ever seen or been with. Honest, straightforward representation with no surprises…I was so impressed that three years later” I’m now a part of the team, helping to insure that we provide that same piece of mind for every new traveler that comes aboard!!!' },
      // tslint:disable-next-line:max-line-length
    { 'name': 'Elisa ER RN', 'quote': 'I have had the good fortune of doing contracts and per diem work through R Visions since 2005. It has allowed me to have awesome flexibility in my life and unique opportunities to work in a wide variety of settings. I have worked with healthcare staff from other companies who reported nightmarish experiences with assignments and pay. I am here to say, I have had none of those experiences. I have complete trust in R Visions; not only in the great folks who run the company, but also in the competent contractors. It\'s a rock solid gig!' },
      // tslint:disable-next-line:max-line-length
    { 'name': 'Joy Telemetry RN', 'quote': 'This company is the REAL DEAL! They not only gave me the highest rates that I could find anywhere, but when any kind of problem arose, I had a NURSE from RVisions on the other end helping me! Not just a recruiter that had no clue what I was going through!' }
  ];


  constructor() { }

  ngOnInit() {

    this.currentQuote = 0;

        // if(this.quoteSet==='recommendations'){
        //     this.quoteInterval = setInterval(() => this.changeQuote(this.currentQuote, this.recommendations), 3500);
        // }else if(this.quoteSet==='testimonies'){
        //     this.quoteInterval = setInterval(() => this.changeQuote(this.currentQuote, this.testimonies), 3500);
        // }

        this.name = this.recommendations[0].name;
        this.quote = this.recommendations[0].quote;
        this.quoteInterval = setInterval(() => this.changeQuote(this.currentQuote), 4500);
  }


  ngOnDestroy() {
    if (this.quoteInterval) {
        clearInterval(this.quoteInterval);
    }
}

  public changeQuote(currentQuote: number) {
    // console.log(this.currentQuote +' of ' + this.recommendations.length);
      this.name = this.recommendations[this.currentQuote].name;
      this.quote = this.recommendations[this.currentQuote].quote;
  if (this.currentQuote === (this.recommendations.length - 1)) {
      this.currentQuote = 0;
  } else {
      this.currentQuote += 1;
  }
  };

  // public interface IQuote {
  //    name: string;
  //    quote: string;
  // };

}
