import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tax-matters',
  templateUrl: './tax-matters.component.html',
  styleUrls: ['./tax-matters.component.css']
})
export class TaxMattersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
