import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ChooseUsComponent } from './components/choose-us/choose-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FeatureComponent } from './components/feature/feature.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormsComponent } from './components/forms/forms.component';
import { HeartBulletComponent } from './components/heart-bullet/heart-bullet.component';
import { HomeComponent } from './components/home/home.component';
import { HospitalsFacilitiesClientsComponent } from './components/hospitals-facilities-clients/hospitals-facilities-clients.component';
import { MeetOurTeamComponent } from './components/meet-our-team/meet-our-team.component';
import { NavBarMainComponent } from './components/navigation/nav-bar-main/nav-bar-main.component';
import { NavBarCurrentTravelersComponent } from './components/navigation/nav-bar-current-travelers/nav-bar-current-travelers.component';
import { PaymentDistributionDatesComponent } from './components/payment-distribution-dates/payment-distribution-dates.component';
import { PaymentInformationComponent } from './components/payment-information/payment-information.component';
import { TaxMattersComponent } from './components/tax-matters/tax-matters.component';
import { TimeClockTipsComponent } from './components/time-clock-tips/time-clock-tips.component';
import { TravelerQuotesComponent } from './components/traveler-quotes/traveler-quotes.component';
import { TravelingProfessionalsComponent } from './components/traveling-professionals/traveling-professionals.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutUsComponent,
    CarouselComponent,
    ChooseUsComponent,
    ContactUsComponent,
    FeatureComponent,
    FooterComponent,
    FormsComponent,
    HeartBulletComponent,
    HomeComponent,
    HospitalsFacilitiesClientsComponent,
    MeetOurTeamComponent,
    NavBarMainComponent,
    PaymentDistributionDatesComponent,
    PaymentInformationComponent,
    TaxMattersComponent,
    TimeClockTipsComponent,
    TravelerQuotesComponent,
    TravelingProfessionalsComponent,
    NavBarCurrentTravelersComponent
  ],
  imports: [BrowserModule, AppRoutingModule, NgbModule.forRoot()],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
