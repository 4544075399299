import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var ga: Function;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  title: string;

  // For Google Analytics
  constructor(router: Router) {
    router.events.subscribe(() => {
      const newRoute =
        window.location.href
          .substring(
            window.location.href.indexOf('#/') + 2,
            window.location.href.length
          )
          .toString() + '.html';
      if (newRoute !== this.title) {
        this.title =
          window.location.href
            .substring(
              window.location.href.indexOf('#/') + 2,
              window.location.href.length
            )
            .toString() + '.html';
        console.log('new route: ' + this.title);
        ga('send', 'pageview', this.title);
      }
    });
  }

  ngOnInit() {}
}
