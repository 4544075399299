import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { TravelingProfessionalsComponent } from './components/traveling-professionals/traveling-professionals.component';
import { PaymentDistributionDatesComponent } from './components/payment-distribution-dates/payment-distribution-dates.component';
import { ChooseUsComponent } from './components/choose-us/choose-us.component';
import { FormsComponent } from './components/forms/forms.component';
import { TaxMattersComponent } from './components/tax-matters/tax-matters.component';
import { HospitalsFacilitiesClientsComponent } from './components/hospitals-facilities-clients/hospitals-facilities-clients.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { PaymentInformationComponent } from './components/payment-information/payment-information.component';
import { MeetOurTeamComponent } from './components/meet-our-team/meet-our-team.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'travelingProfessionals', component: TravelingProfessionalsComponent },
  { path: 'paymentsinfo', component: PaymentInformationComponent },
  { path: 'paymentDistributionDates', component: PaymentDistributionDatesComponent },
  { path: 'chooseUs', component: ChooseUsComponent },
  { path: 'forms', component: FormsComponent },
  { path: 'taxMatters', component: TaxMattersComponent },
  { path: 'hospitalsfacilitiesclients', component: HospitalsFacilitiesClientsComponent },
  { path: 'AboutUs', component: AboutUsComponent },
  { path: 'ContactUs', component: ContactUsComponent },
  { path: 'MeetRVisionsTeam', component: MeetOurTeamComponent },
  { path: 'apply', redirectTo: 'https://ctms.contingenttalentmanagement.com/instaffing/WorkforcePortal/login.cfm'},
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
