import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-traveling-professionals',
  templateUrl: './traveling-professionals.component.html',
  styleUrls: ['./traveling-professionals.component.css']
})
export class TravelingProfessionalsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
