import { Component, OnInit, Attribute } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  format: any;
  public date;

  constructor( @Attribute('format') format) {
      this.format = format;
      this.date = new Date();
  }

  ngOnInit() {
  }

}
