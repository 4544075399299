import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-time-clock-tips',
  templateUrl: './time-clock-tips.component.html',
  styleUrls: ['./time-clock-tips.component.css']
})
export class TimeClockTipsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
